import { Input } from '@/components/input'
import { useAuth } from '@/hooks/auth'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styles from '../styles/Login.module.css'
import { Button } from '@/components/button'

interface IUserAuth {
    email: string,
    password: string,
}

export default function Login(): JSX.Element {
    const { login, user } = useAuth()
    const { push } = useRouter()

    const [state, setState] = useState<IUserAuth>({ email: '', password: '' })

    async function handleLoginSubmit(event: React.FormEvent): Promise<void> {
        event.preventDefault()

        login(state.email, state.password, async () => push('/admin'))
    }

    function onChange(propertyName: string, value: string): void {
        setState((state): IUserAuth => {
            return { ...state, [propertyName]: value }
        })
    }

    return <main className={styles.page}>
        <div className={styles.image}>

        </div>
        <section className={styles.formLogin}>
            <form onSubmit={handleLoginSubmit}>
                <div className={styles.header}>
                    <h1>Entrar</h1>
                </div>
                <div className={styles.inputs}>
                    <Input
                        label='Email'
                        type='email'
                        onChange={(event): void => onChange('email', event.target.value)}
                    />
                    <Input
                        label='Senha'
                        type='password'
                        onChange={(event): void => onChange('password', event.target.value)}
                    />
                </div>
                <div className={styles.actions}>
                    <Button label='Entrar' onClick={()=>{}}/>
                </div>
            </form>
            <section className={styles.footer}>
                <span>© {new Date().getFullYear()} Rádio Escotilha. Todos os direitos reservados.</span>
            </section>
        </section>
    </main>
}
