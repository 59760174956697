import styles from './input.module.css'

type InputProperties = {
    label?: string,
    color?: 'red' | 'blue' | 'yellow' | 'green' | undefined
    onClick?: () => void
}

export function Button(inputProps: InputProperties): JSX.Element {
    return <button className={[styles.btn, styles[inputProps.color??'red']].join(' ')} onClick={inputProps.onClick}>
        {inputProps.label}
    </button>
}
