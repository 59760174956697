import { HTMLInputTypeAttribute, InputHTMLAttributes, useState } from 'react'
import styles from './input.module.css'

type InputProperties = InputHTMLAttributes<HTMLInputElement> & {
    label?: string,
    helpertext?: string
    required?: boolean
}

export function Input(inputProps: InputProperties): JSX.Element {
    const { label, helpertext, required, className, ...props } = inputProps

    return <>
        <div className={styles.coolinput}>
            <label className={styles.text}>{label} <strong className={styles.error}>{required && '*'}</strong></label>
            <input name="input" className={[className, styles.input].join(' ')} {...props} />
            {helpertext && <label className={styles.error}>{helpertext}</label>}
        </div>
    </>
}
